@font-face {
    font-family: "bennet-text-one";
    src: url("https://use.typekit.net/af/a3821f/00000000000000003b9b0176/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff2"), url("https://use.typekit.net/af/a3821f/00000000000000003b9b0176/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("woff"), url("https://use.typekit.net/af/a3821f/00000000000000003b9b0176/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i4&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 400;
}

@font-face {
    font-family: "bennet-text-one";
    src: url("https://use.typekit.net/af/15ae15/00000000000000003b9b0177/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/15ae15/00000000000000003b9b0177/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/15ae15/00000000000000003b9b0177/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "bennet-text-one";
    src: url("https://use.typekit.net/af/9a8935/00000000000000003b9b0178/27/l?subset_id=2&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/9a8935/00000000000000003b9b0178/27/d?subset_id=2&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/9a8935/00000000000000003b9b0178/27/a?subset_id=2&fvd=n6&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 600;
}

@font-face {
    font-family: "bennet-text-one";
    src: url("https://use.typekit.net/af/5600e2/00000000000000003b9b017a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/5600e2/00000000000000003b9b017a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/5600e2/00000000000000003b9b017a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-display: auto;
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "bennet-text-one";
    src: url("https://use.typekit.net/af/f91709/00000000000000003b9b017b/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff2"), url("https://use.typekit.net/af/f91709/00000000000000003b9b017b/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("woff"), url("https://use.typekit.net/af/f91709/00000000000000003b9b017b/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=i7&v=3") format("opentype");
    font-display: auto;
    font-style: italic;
    font-weight: 700;
}
