.footer-site-icon {
    width: 75px;
}

@media screen and (max-width: 557px) {
    .footer-site-icon {
        width: 64px;
    }
}

.table-nowrap {
    table-layout: fixed;
    flex-wrap: nowrap;
}

@media screen and (min-width: 600px) {
    
    .table-nowrap tr td, .table-nowrap tr th {
        width: 1%;
        max-width: 33.33% !important;
    }
}