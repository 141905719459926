
$font-family-sans-serif: 'bennet-text-one',
sans-serif;
$primary: #245AE5;

@import "../src/assets/fonts/bennet-text-one.css";
@import "../node_modules//bootstrap/scss/bootstrap.scss";

body {
    background-color: #E2E3DE !important;
    scroll-behavior: smooth !important;
}

.fs-obviously {
    font-family: "obviously", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
}

.fs-obviously-normal {
    font-family: "obviously", sans-serif !important;
    font-weight: normal !important;
    font-style: normal;
}

.fs-obviously-wide {
    font-family: "obviously-wide", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
}

.fs-obviously-extended {
    font-family: "obviously-extended", sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
}

.fs-bennet-text-one {
    font-family: "bennet-text-one", sans-serif !important;
    font-style: normal;
    font-weight: normal;
}

.nav-link {
    text-transform: uppercase;
}

.navbar-icon {
    width: 48px;
}

.btn {
    border-width: 2px;
    text-transform: uppercase;
    font-family: obviously, sans-serif !important;
    font-weight: 600 !important;
    font-style: normal;
    letter-spacing: 0.1em;
    font-style: normal;
}

.change-info-link {
    font-family: "bennet-text-one", sans-serif !important;
    font-size: small !important;
    font-weight: normal !important;
    text-transform: lowercase;
}

.form-control {
    font-family: bennet-text-one, sans-serif !important;
    font-style: normal;
    font-weight: normal;
}

.form-control:focus,
.form-control:active,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

.btn-circle {
    width: 30px;
    height: 30px;
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;
}


.frame-cart {
    background-image: url(assets/icons/cart.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
}


@media screen and (max-width: 480px) {
    .main-wrapper {
        padding: 0 !important;
    }
}


.absolute-grad-col {
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    width: 50px !important;
    background-image: url('assets/resources/bar.svg');
}

@media screen and (max-width: 564px) {
    .absolute-grad-col {
        width: 25px !important;
    }

    .navbar-icon {
        width: 38px;
    }

}

.react-responsive-modal-root {
    z-index: 3430 !important;
}

.line-heading {
    display: inline-block;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.15);
    vertical-align: middle;
}

.customizeModal {
    max-height: 75vh !important;
    position: relative;
}
.react-responsive-modal-closeButton {
    right: 20px !important
}

.vl {
    border-left: 1px solid #6c757d !important;
    display: inline-block;
    height: 100px;
}

@media screen and (max-width: 564px) {
    .vl {
        height: 35px !important;
    }
}

@media screen and (max-width: 564px) and (min-width: 481px) {

    .navbar-padding {
        padding-right: 3rem !important;
        padding-left: 3rem !important;
    }
}

.marquee-wrapper {
    position: absolute;
    z-index: 1025;
    min-width: 100%;
    left: 0;
    right: 0;
    top: 55px;
}

.marquee-bg {
    background: #DFFF61 !important;
}

.ticker__element,
.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap>div {
    backface-visibility: hidden !important;
    -webkit-backface-visibility: hidden !important;

}

.react-slideshow-fade-wrapper .react-slideshow-fade-images-wrap {
    align-items: end;
}

.react-slider-image-width {
    width: 1650px;
    height: 300px;
    position: relative;
    z-index: 2;
    object-fit: scale-down;
    object-position: bottom;
}

@media only screen and (max-width: 480px) {

    .react-slider-image-width {
        height: 200px;
    }
}

@media only screen and (min-width: 1024px) {

    .react-slider-image-width {
        height: 600px;
    }
}



.slider-white-frame {
    top: 45%;
    width: 375px;
}

@media only screen and (max-width: 320px) {

    .slider-white-frame {
        top: 65% !important;
    }
}

@media only screen and (max-width: 480px) {

    .slider-white-frame {
        top: 60%;
        width: 100px;
    }
}

@media only screen and (min-width: 481px) and (max-width: 599px) {

    .slider-white-frame {
        top: 55%;
        width: 200px;
    }
}

@media screen and (min-width: 600px) and (max-width: 991px) {
    .slider-white-frame {
        top: 50%;
        width: 200px;
    }

    .navbar-icon {
        width: 42px;
    }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {

    .slider-white-frame {
        top: 55%;
        width: 300px;
    }
}

@media screen and (min-width: 1900px) {
    .slider-white-frame {
        top: 45%;
        width: 350px;
    }
}



.btn-mat {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
    box-sizing: border-box;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    border: none;
    -webkit-tap-highlight-color: transparent;
    display: inline-block;
    white-space: nowrap;
    text-decoration: none;
    vertical-align: baseline;
    text-align: center;
    margin: 5px 0;
    min-width: 88px;
    line-height: 36px;
    padding: 0 16px;
    border-radius: 2px;
    overflow: visible;
    transform: translate3d(0, 0, 0);
    transition: background .4s cubic-bezier(.25, .8, .25, 1), box-shadow 280ms cubic-bezier(.4, 0, .2, 1);
}



.btn-mat:not([disabled]):active {
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, .2), 0 8px 10px 1px rgba(0, 0, 0, .14), 0 3px 14px 2px rgba(0, 0, 0, .12);
}

.btn-mat:hover,
.btn-mat:focus,
.btn-mat:active {
    border: none !important;
    outline: 0 !important;
}

.btn-mat.btn-link,
.btn-mat.btn-link.active,
.btn-mat.btn-link:active {
    background-color: #fff !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.btn-mat.btn-link {
    box-shadow: none !important;
    background: transparent !important;
    text-decoration: none !important;
}

/*Link Default*/
.btn-mat.btn-link.btn-mat-link-default {
    color: #333;
}

.btn-mat.btn-link.btn-mat-link-default:hover,
.btn-mat.btn-link.btn-mat-link-default:focus,
.btn-mat.btn-link.btn-mat-link-default:active,
.btn-mat.btn-link.btn-mat-link-default.active,
.btn-mat.btn-link.btn-mat-link-default.active:hover,
.btn-mat.btn-link.btn-mat-link-default.active:focus {
    background: rgba(171, 171, 171, 0.1) !important;
}


@supports (-webkit-overflow-scrolling: touch) {
    .modal-dialog-scrollable .modal-body {
        overflow-y: scroll !important;
    }
}

.disable-user-events-on-images {
    pointer-events: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
}

.text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
}

.text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.text-2xsm {
    font-size: 0.75rem;
    line-height: 1.5rem;
}
