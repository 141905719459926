.scroll-to-bottom {
    z-index: 2500;
    top: 50%;
    left: 0;
    transform: translate(40%, -50%);
}

.right-side-elem {
    float: right;
    right: 12.1px !important;
    top: 15%;

    transform: rotate(270deg);
    transform-origin: 100% 100%;
    flex-direction: row-reverse;

    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform-origin: 100% 100%;
    -o-transform-origin: 100% 100%;
    -webkit-transform-origin: 100% 100%;

    z-index: 500;
}

.right-text {
    background-color: #DFFF61;
    letter-spacing: 2px;
    padding: 15px 25px !important;
    position: relative;
    float: right;
    transform: scale(-1);
}

.right-logo {
    width: 125px;
    transform: scale(-1);
}

.left-side-elem {
    z-index: 500;
}

.ripple {
    background-position: center;
    transition: background 0.8s;
}

.ripple:active {
    background-color: rgba(255, 255, 255, 0.2);
    background-size: 100%;
    transition: background 0s;
}

.peace-symbole {
    background-image: url('../../assets/icons/scroll-to-bottom.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 32px;
    width: 32px;
}



@media screen and (max-width: 480px) {

    .scroll-to-bottom {
        transform: translate(25%, -50%);
    }

    .right-side-elem {
        right: 1px !important;
        top: 10% !important;
    }

    .right-text {
        padding: 5px 25px !important;
    }

}


@media screen and (min-width: 481px) and (max-width: 564px) {

    .right-side-elem {
        right: 1px !important;
        top: 10% !important;
    }

    .right-text {
        padding: 5px 25px !important;
    }
}
