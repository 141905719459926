.material-group {
    margin-bottom: 40px;
}

.material-group .form-control {
    border-radius: 0 !important;
    border: none !important;
    border-bottom: 1px solid #121212 !important;
    box-shadow: none;
    background: transparent !important;
    padding: 0.375rem 5px !important;

}

.material-group {
    position: relative;
    margin-bottom: 45px;
}


.material-group input:focus {
    outline: none;
}


.material-group label {
    color: #999;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 0;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
}


.material-group input:focus~label,
.material-group input:valid~label {
    top: -20px;
    font-size: 14px;
    font-weight: 500;
    color: #245AE5;

}

.material-group .bar {
    position: relative;
    display: block;
    width: 100%;
}

.material-group .bar:before,
.material-group .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 0px;
    position: absolute;
    background: #245AE5;
    transition: 0.4s ease all;
    -moz-transition: 0.4s ease all;
    -webkit-transition: 0.4s ease all;
}

.material-group .bar:before {
    left: 50%;
}

.material-group .bar:after {
    right: 50%;
}


.material-group input:focus~.bar:before,
.material-group input:focus~.bar:after {
    width: 50%;
}

.material-group .highlight {
    position: absolute;
    width: 100px;
    top: 25%;
    left: 0;
    pointer-events: none;
    opacity: 0.5;
}

/* active state */
.material-group input:focus~.highlight {
    -webkit-animation: inputHighlighter 0.3s ease;
    -moz-animation: inputHighlighter 0.3s ease;
    animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@-moz-keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}

@keyframes inputHighlighter {
    from {
        background: #5264AE;
    }

    to {
        width: 0;
        background: transparent;
    }
}



.text-material-group textarea {
    display: block;
    background: none;
    padding: 0.125rem 0.125rem 0.0625rem;
    border-width: 0;
    border-color: transparent;
    line-height: 1.9;
    width: 100%;
    -webkit-transition: all 0.28s ease;
    transition: all 0.28s ease;
    box-shadow: none;
}

.text-material-group textarea:focus~.input-label,
.text-material-group textarea:valid~.input-label,
.text-material-group textarea.form-file~.input-label,
.text-material-group textarea.has-value~.input-label {
    font-size: 14px;
    color: gray;
    top: -1rem;
    left: 0;
}

.text-material-group textarea:focus~.input-label {
    color: #245AE5;
}

.text-material-group textarea:focus~.bar::before {
    width: 100%;
    left: 0;
}

.text-material-group {
    position: relative;
    margin-top: 2.25rem;
    margin-bottom: 4.25rem;
}
