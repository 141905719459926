.page-bg {
    min-height: 100vh;
    background-color: #EDEEF2 !important;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '>' !important;
    color: #ACB1C5 !important;
}

.breadcrumb-item {
    color: #ACB1C5 !important;
}

.breadcrumb-item.active-step {
    color: #000000 !important;
}

.form-installation-check-input:checked {
    background-color: #ACB1C5 !important;
    border-color: #ACB1C5 !important;
}

.btn-label {
    position: relative;
    display: inline-block;
    padding: 0;
    left: 0;
    bottom: 1.25px;
}

.btn-labeled {
    padding: 0.25rem 0.5rem !important;
}