.wavy span {
    animation: animate 1.5s ease-in-out;
    animation-iteration-count: 1;
    backface-visibility: hidden;
}

@keyframes animate {

    0%,
    40%,
    100% {
        transform: translateY(0)
    }

    20% {
        transform: translateY(-20px)
    }
}

.wavy-text span {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    animation-delay: calc(.1s * var(--i));
}

@media screen and (max-width: 564px) {
    .wavy-text span {
        font-size: x-small;
    }
}
